<template>
  <div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import {getAuth, signInAnonymously} from 'firebase/auth'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'
import {messaging} from "@/plugins/firebase";
import  {mapGetters,mapActions} from "vuex";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },

  async created() {
    if (this.getUser.HrDepartment.name === 'CAJAS' || this.getUser.HrDepartment.name === 'FARMACIA' || this.getUser.HrDepartment.name === 'ENFERMERIA'){
      await this.authenticate(this.getUser.HrDepartment.name);
    }
  },
  methods:{
    ...mapActions('auth',['createTopic']),
    async authenticate(topic){
      await signInAnonymously(getAuth())
      await this.activated(topic)
    },
    async activated(topic){
      const token = await getToken(messaging,{
          vapidKey:'BAuM8xJ5FZGW6sVFz8ltY2dkMuN48IU_xuywK0vFBNeHdnGWbTohbqW7lg9MYZHFPO-bB3JsMHg2KQNA2Bn-nYE',
      })
      const send = {
        topic,
        token
      }
      await this.createTopic(send)
    }
  },
  computed:{
    ...mapGetters('auth',['getUser','getToken'])
  }
}
</script>

<style>

</style>
